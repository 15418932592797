<template>
    <div class="pro6Com2">
        <div class="p62Box">
            <!-- 标题 -->
            <div class="p62Title wow animate__animated animate__fadeInUp">
                <p class="p62Big">威有企业云落地服务</p>
                <p class="p62Small">
                    我们为使用云计算的用户提供完整的服务您有技术问题咨询、技术问题处理、维护 可以在不用远程下单长期<br />等待厂商回复，直接雇佣我们来解决在使用云计算中所需要解决的问题。
                </p>
            </div>
            <!-- 图片 -->
            <div class="p62Imgs wow animate__animated animate__fadeInUp">
                <img src="http://iv.okvu.cn/vugw/img/pc621.png" alt="" class="p62Img" />
            </div>
            <!-- 方案列表 -->
            <div class="p62List wow animate__animated animate__fadeInUp">
                <ul>
                    <li class="p62Li">
                        <p class="p62LBig">方案一专业的咨询与规划</p>
                        <p class="p62LSmall">
                            虽然使用云计算能让一个普通的公司也拥有像威有、威有一样的技术支撑能力，但如何选择合适自己的云产品和技术？威有帮助您很好的理解和选择云计算产品，以及如何使云计算产品与您公司现有的IT进行无缝的对接。
                        </p>
                    </li>
                    <li class="p62Li">
                        <p class="p62LBig">迁移一周密计划与面对面服务</p>
                        <p class="p62LSmall">
                            在选择恰当云计算产品后，我们对原系统、数据及文件内容做详细的搬家计划。威有作为威有云金牌服务商，会帮助您制定出整个迁移所需要的工作以及工作量，
                            并且在不影响原业务情况下完成云计算的切换，即将完成时提供我们的报告。
                        </p>
                    </li>
                    <li class="p62Li">
                        <p class="p62LBig">优化维护一找一堆不如选我们</p>
                        <p class="p62LSmall">
                            使用了云计算，已经节省了您大量的运维开支，但这不是一切。传统物理服务器、机房一面临的众多问题一样会出现，比如应用程序仍然需要维护、运用系统本身安全漏洞而产生的攻击等等。这个时候您需要一个专业运维队伍，才能保障您的业务7X24小时可用，与
                            其自己去寻找雇佣这样一支队伍，不如选择我们运维团队来帮您搞定一切。
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Pro6Com2",
    data() {
        return {};
    },
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less">
.pro6Com2 {
    min-width: 1423px;
    height: 800px;
    margin-top: 50px;
    .p62Box {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // 标题
        .p62Title {
            width: 80%;
            height: 100px;
            text-align: center;
            margin: 0 auto;
            .p62Big {
                width: 100%;
                height: 50px;
                line-height: 50px;
                font-size: 40px;
                font-weight: 900;
            }
            .p62Small {
                width: 100%;
                height: 25px;
                line-height: 25px;
            }
        }
        // 图片
        .p62Imgs {
            width: 100%;
            height: 250px;
            margin-top: 20px;
            text-align: center;
        }
        // 方案列表
        .p62List {
            width: 100%;
            height: 400px;
            text-align: left;
            ul {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                .p62Li {
                    width: 100%;
                    height: 100px;
                    .p62LBig {
                        width: 100%;
                        height: 50px;
                        line-height: 50px;
                        font-size: 18px;
                        font-weight: 900;
                    }
                    .p62LSmall {
                        width: 100%;
                        height: 25px;
                        line-height: 25px;
                    }
                }
            }
        }
    }
}
</style>
