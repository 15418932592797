<template>
    <div class="pro6Com1" v-if="getAdvData(31).children">
        <a :href="getAdvData(31).children[0].url" target="_blank" rel="noopener noreferrer">
            <img :src="getAdvData(31).children[0].image" class="p6Bg" />
            <div class="p61Box">
                <!-- 左图文 -->
                <div class="p61Left">
                    <div class="p61Top">
                        <img
                            :src="getAdvData(31).children[0].children[0].image"
                            class="p61Img wow animate__animated animate__slideInRight"
                        />
                    </div>
                    <div class="p61Bottom wow animate__animated animate__slideInUp">
                        <ul>
                            <li class="p61Li">云服务器部署</li>
                            <li class="p61Li">云数据库</li>
                            <li class="p61Li">存储与CDN</li>
                            <li class="p61Li">网络与负载均衡</li>
                            <li class="p61Li">云盾</li>
                            <li class="p61Li">云监控</li>
                        </ul>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "Pro6Com1",
    inject: ["getAdvData"],
    mounted() {
        this.$AOS.init({
            offset: 200,
            duration: 600,
            easing: "ease-in-sine",
            delay: 100,
        });
        new this.$wow.WOW().init({
            boxClass: "wow",
            animateClass: "animate__animated",
            offset: 0,
            mobile: true,
            live: true,
        });
    },
};
</script>

<style lang="less">
.pro6Com1 {
    min-width: 1440px;
    // height: 500px;
    position: relative;
    .p6Bg {
        width: 100%;
        // height: 100%;
        position: relative;
        z-index: -1;
    }
    .p61Box {
        width: 1280px;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        // 左图文
        .p61Left {
            width: 55%;
            height: 100%;
            padding-top: 50px;
            .p61Top {
                width: 100%;
                height: 40%;
            }
            .p61Bottom {
                width: 80%;
                height: 150px;
                margin-left: 20%;
                ul {
                    width: 100%;
                    height: 120%;
                    display: flex;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    .p61Li {
                        width: 150px;
                        height: 50px;
                        line-height: 50px;
                        font-weight: 900;
                        font-size: 18px;
                        text-align: center;
                        color: black;
                        box-shadow: 0 5px 11px rgba(119, 129, 241, 0.603), 0 -5px 11px rgba(119, 129, 241, 0.603),
                            -5px 0 11px rgba(119, 129, 241, 0.603), 5px 0 11px rgba(119, 129, 241, 0.603);
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}
</style>
