<template>
    <div class="proContent6">
        <Pro6Com1></Pro6Com1>
        <Pro6Com2></Pro6Com2>
        <Pro6Com3></Pro6Com3>
    </div>
</template>

<script>
import Pro6Com1 from "./Pro6Com1.vue";
import Pro6Com2 from "./Pro6Com2.vue";
import Pro6Com3 from "./Pro6Com3.vue";
export default {
    name: "proContent6",
    components: {
        Pro6Com1,
        Pro6Com2,
        Pro6Com3,
    },
};
</script>

<style lang="less">
.proContent6 {
    overflow: hidden;
}
</style>
